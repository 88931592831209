$blue: #06557e;
$desktop: 960px;
$mobile: 425px;
$grid-width: 1170px;

.cookieNotification {
  max-width: 100%;
  padding: 10px 15px;
  font-family: 'din_regular', arial, sans-serif;
  background-color: $blue;

  &__grid {
    margin: 0 auto;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    max-width: $grid-width;

    @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
      display: block;
    }
  }

  &__col {
    padding-right: 15px;

    & + & {
      padding-right: 0;
      padding-left: 0;
    }

    @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
      &:last-child {
        margin-top: 10px;
      }
    }
  }

  &__text,
  &__button {
    font-size: 14px;
    line-height: 18px;
    color: white;
  }

  &__button {
    appearance: none;
    border: 1px solid white;
    border-radius: 0;
    display: block;
    padding: 4px 14px;
    vertical-align: middle;
    font-family: 'din_regular', arial, sans-serif;
    text-decoration: none;
    background-color: transparent;
    touch-action: auto;
    min-width: 120px;

    @media (min-width: $desktop) {
      cursor: pointer;
    }

    &:active,
    &:hover {
      @media (min-width: $desktop) {
        color: $blue;
        background-color: white;
      }
    }

    & span {
      display: block;
      user-select: none;
    }

    &:first-child {
      margin-bottom: 5px;
    }

    @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
      display: inline-block;
      margin-bottom: 0 !important;
    }
  }
}

.-hidden {
  display: none;
}

.cookieModal {
  position: fixed;
  //overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;

  &__fade {
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &__container {
    position: relative;
    background-color: #FFFFFF;
    border-radius: 2px;
    margin: 10% auto;
    height: 450px;
    width: 730px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15), 0 7px 14px 0 rgba(50,50,93,.1);

    @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
  }

  &__header h2 {
    font-family: din_bold,arial,sans-serif;
    color: #888;
    font-size: 18px;
    font-weight: 500;
    margin: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close {
    color: #888;
    font-size: 17px;
    margin: 0 15px;

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    height: calc(100% - 120px);
    overflow-y: hidden;
    background-color: white;
    color: #666;
  }

  &__category {
    border-bottom: 1px solid #d8d8d8;
    padding: 20px;

    &:hover {
      cursor: pointer;
    }

    &.-active {
      background-color: #06557e;
      display: inherit;

      & .cookieModal__categoryTitle {
        color: white;
      }
    }
  }

  &__categories {
    width: 30%;
    float: left;
    padding: 0;
    margin: 0;
    list-style: none;

    @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
      width: inherit;
      float: inherit;
    }
  }

  &__categoryTitle {
    font-family: din_bold,arial,sans-serif;
    line-height: 24px;
    font-size: 16px;
    color: #666;
  }

  &__categoryText {
    width: 70%;
    height: 100%;
    background-color: #06557e;
    margin-left: 0;
    overflow-y: scroll;
    left: 30%;
    padding: 10px 20px;
    //position: absolute;
    float: none;
    box-sizing: border-box;
    display: none;

    @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
      width: inherit;
      left: 0;
      height: calc(100% - 200px);
    }

    p {
      color: white;
      font-size: 14px;
      line-height: 20px;
    }

    table {
      font-family: din_regular,arial,sans-serif;
      font-size: 14px;
      line-height: 20px;
      margin-top: 10px;
      border-collapse: collapse;
      border: 1px solid #ccc;
      border-spacing: 10px;

      td {
        padding: 7px;
        border: 1px solid #ccc;

        > p {
          margin-top: 0;
        }
      }
    }

    &.-active {
      display: block;
    }
  }

  &__categoryTextHeading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__buttons {
    padding: 0;
    width: 100%;
    bottom: 0;
    background-color: white;
    min-height: 70px;
  }

  &__buttonGroup {
    display: flex;
    float: right;
    text-align: center;
    margin: 15px;
  }

  button.cookieAction {
    border-radius: 4px;
    height: 40px;
    padding: 0 21px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    opacity: 1;
    transition-property: background-color, border-radius, width;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0, 1);
    background-color: white;
    font-family: din_bold,arial,sans-serif;
    margin-right: 10px;
    color: #303030;
    border: 2px solid #06557e;

    &:hover {
      cursor: pointer;
      background-color: #06557e;
      color: white;
    }

    &.-solid {
      background-color: #06557e;
      color: white;

      &:hover {
        color: #303030;
        background-color: white;
      }
    }
  }
}
